import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  CreCapitalDatabaseFilterComponent,
  ProgramFilterComponent,
  StateDatabaseFilterComponent,
} from 'src/app/components';
import { DashboardFilterComponent } from 'src/app/components/dashboard';
import { ProjectFilterComponent } from 'src/app/components/projects';
import { ADVANCED_FILTER_TYPES } from 'src/app/constants';

@Component({
  selector: 'app-advanced-filter',
  templateUrl: './advanced-filter.component.html',
  styleUrls: ['./advanced-filter.component.scss'],
})
export class AdvancedFilterComponent {
  @Input() type: string = '';
  @Input() lenderList: any[] = [];
  @Input() advanceFilter: any;
  @Input() propertyTypeList: any;

  // @ViewChild('assessmentBillingTaskFilter')
  // assessmentBillingTaskFilter: AssessmentBillingTaskFilterComponent;
  @ViewChild('dashboardFilter')
  dashboardFilter: DashboardFilterComponent;
  @ViewChild('stateDatabaseFilter')
  stateDatabaseFilter: StateDatabaseFilterComponent;
  @ViewChild('programFilter')
  programFilter: ProgramFilterComponent;
  @ViewChild('creDatabaseFilter')
  creDatabaseFilter: CreCapitalDatabaseFilterComponent;

  @ViewChild('projectFilter')
  projectFilter!: ProjectFilterComponent;

  @Output() handleResetFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleSubmitAdvanceFilter: EventEmitter<any> =
    new EventEmitter<any>();

  ADVANCED_FILTER_TYPES = ADVANCED_FILTER_TYPES;

  constructor() {}

  ngOnInit() {}

  // async fetchRequiredData() {
  //   const childComponent = this.getActiveChild();
  //   if (childComponent) {
  //     childComponent.fetchRequiredData &&
  //       (await childComponent.fetchRequiredData());
  //   }
  // }

  resetFilter() {
    const childComponent = this.getActiveChild();
    if (childComponent) {
      childComponent.resetFilter();
      this.handleResetFilter.emit();
    }
  }

  submitAdvanceFilter() {
    const childComponent = this.getActiveChild();
    if (childComponent) {
      this.handleSubmitAdvanceFilter.emit({
        filterFormValue: childComponent.filterForm.value,
        filterOptions: this.getFilterOptions(childComponent),
      });
    }
  }

  getActiveChild(): any {
    switch (this.type) {
      // case ADVANCED_FILTER_TYPES.ASSESSMENT_BILLING_TASK: {
      //   return this.assessmentBillingTaskFilter;
      // }
      case ADVANCED_FILTER_TYPES.DASHBOARD: {
        return this.dashboardFilter;
      }
      case ADVANCED_FILTER_TYPES.STATE_DATABASE: {
        return this.stateDatabaseFilter;
      }
      case ADVANCED_FILTER_TYPES.PROGRAM: {
        return this.programFilter;
      }
      case ADVANCED_FILTER_TYPES.PROJECT: {
        return this.projectFilter;
      }
      case ADVANCED_FILTER_TYPES.LENDER_DATABASE: {
        return this.creDatabaseFilter;
      }
      default: {
        return false;
      }
    }
  }

  async fetchRequiredData() {
    const childComponent = this.getActiveChild();
    if (childComponent) {
      childComponent.fetchRequiredData &&
        (await childComponent.fetchRequiredData());
    }
  }

  getFilterOptions(childComponent: any) {
    switch (this.type) {
      case ADVANCED_FILTER_TYPES.ASSESSMENT_BILLING_TASK: {
        return {
          filterPortfolio: childComponent.filterPortfolio,
          filterCaseType: childComponent.filterCaseType,
          filterTimeline: childComponent.filterTimeline,
          filterState: childComponent.filterState,
        };
      }
      case ADVANCED_FILTER_TYPES.DASHBOARD: {
        return {
          filterPropertyType: childComponent.filterPropertyType,
          filterProformaPhase: childComponent.filterProformaPhase,
          filterState: childComponent.filterState,
          filterCloseDate: childComponent.filterCloseDate,
          filterRangeDate: childComponent.filterRangeDate,
          filterUser: childComponent.filterUser,
          filterTransactionManager: childComponent.filterTransactionManager,
          filterTransactionAnalyst: childComponent.filterTransactionAnalyst,
          filterMarketLeader: childComponent.filterMarketLeader,
          filterUserStatus: childComponent.filterUserStatus,
        };
      }
      case ADVANCED_FILTER_TYPES.STATE_DATABASE: {
        return {
          filterIsStateActiveForFunding:
            childComponent.filterIsStateActiveForFunding,
        };
      }
      case ADVANCED_FILTER_TYPES.PROGRAM: {
        return {
          filterState: childComponent.filterState,
        };
      }
      case ADVANCED_FILTER_TYPES.PROJECT: {
        return {
          filterProgram: childComponent.filterProgram,
          filterProformaPhase: childComponent.filterProformaPhase,
          filterMarketLeader: childComponent.filterMarketLeader,
          filterPropertyType: childComponent.filterPropertyType,
        };
      }
      case ADVANCED_FILTER_TYPES.LENDER_DATABASE: {
        return {
          filterLoanSize: childComponent.filterLoanSize,
          filterPropertyType: childComponent.filterPropertyType,
          filterState: childComponent.filterState,
          filterFundingTypes: childComponent.filterFundingTypes,
          filterPreferredLender: childComponent.filterPreferredLender,
          filterIncludeProjects: childComponent.filterIncludeProjects,
          filterProjects: childComponent.filterProjects,
        };
      }

      default: {
        return {};
      }
    }
  }
}
