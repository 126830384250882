import { Injectable } from '@angular/core';
import { MdbNotificationService } from 'mdb-angular-ui-kit/notification';
import { ToastrComponent } from '../components/common';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  constructor(private notificationService: MdbNotificationService) {}

  success(detail: string, title: string ='Success', life?: number): void {
    this.showToast(
      'toast-success',
      'fas fa-check fa-lg me-2',
      title,
      detail,
      life
    );
  }

  info(detail: string, title: string ='Info', life?: number): void {
    this.showToast(
      'toast-primary',
      'fas fa-info-circle fa-lg me-2',
      title,
      detail,
      life
    );
  }

  warning(detail: string, title: string ='Warning', life?: number): void {
    this.showToast(
      'toast-warning',
      'fas fa-exclamation-triangle fa-lg me-2',
      title,
      detail,
      life
    );
  }

  error(detail: string, title: string ='Error', life?: number): void {
    this.showToast('toast-danger', 'fas fa-exclamation-circle fa-lg me-2',title, detail, life);
  }

  showToast(
    severity: string,
    icon: string,
    summary: string,
    detail: string,
    life: number = 3000
  ): void {
    this.notificationService.open(ToastrComponent, {
      delay: life,
      autohide: true,
      stacking: false,
      data: { severity, icon, summary, detail },
    });
  }
}
