import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ADVANCED_FILTER_TYPES } from 'src/app/constants';

@Component({
  selector: 'app-advanced-filter-chips',
  templateUrl: './advanced-filter-chips.component.html',
  styleUrls: ['./advanced-filter-chips.component.scss'],
})
export class AdvancedFilterChipsComponent {
  @Input() filterList: any[] = [];
  @Input() type: string = '';
  @Input() advancedFilter: any;

  @Output() handleResetFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() handleSubmitAdvanceFilter: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() handleRemoveFilter: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  removeFilter(filter: any) {
    const childComponent = this.getActiveChild();
    if (childComponent) {
      let filteredData = childComponent.filterForm.get(filter.formField).value;
      if (
        filteredData &&
        filteredData.length &&
        typeof filteredData == 'object'
      ) {
        if (
          filter.formField == 'range' ||
          filter.formField == 'closeRange' ||
          filter.formField == 'pdaRange'
        ) {
          filteredData = null;
          childComponent.filterForm.controls[`${filter.formField}`].setValue(
            null
          );
          this.handleRemoveFilter.emit(filter);
        } else {
          let removeIndex = filteredData.findIndex(
            (x: any) => x == filter.value
          );
          if (removeIndex > -1) {
            filteredData.splice(removeIndex, 1);
          }
        }
      }
      childComponent.filterForm.controls[`${filter.formField}`].setValue(null);
      childComponent.filterForm.controls[`${filter.formField}`].setValue(
        filteredData && typeof filteredData == 'object' ? filteredData : null
      );
      this.handleSubmitAdvanceFilter.emit({
        filterFormValue: childComponent.filterForm.value,
        filterOptions: this.getFilterOptions(childComponent),
      });
    }
  }

  resetFilter() {
    const childComponent = this.getActiveChild();
    if (childComponent) {
      childComponent.resetFilter();
      this.handleResetFilter.emit();
    }
  }

  getActiveChild() {
    switch (this.type) {
      case ADVANCED_FILTER_TYPES.DASHBOARD: {
        return this.advancedFilter.dashboardFilter;
      }
      case ADVANCED_FILTER_TYPES.PROGRAM: {
        return this.advancedFilter.programFilter;
      }
      case ADVANCED_FILTER_TYPES.PROJECT: {
        return this.advancedFilter.projectFilter;
      }
      case ADVANCED_FILTER_TYPES.LENDER_DATABASE: {
        return this.advancedFilter.creDatabaseFilter;
      }
      case ADVANCED_FILTER_TYPES.STATE_DATABASE: {
        return this.advancedFilter.stateDatabaseFilter;
      }
      default: {
        return false;
      }
    }
  }

  getFilterOptions(childComponent: any) {
    switch (this.type) {
      case ADVANCED_FILTER_TYPES.DASHBOARD: {
        return {
          filterPropertyType: childComponent.filterPropertyType,
          filterProformaPhase: childComponent.filterProformaPhase,
          filterState: childComponent.filterState,
          filterCloseDate: childComponent.filterCloseDate,
          filterRangeDate: childComponent.filterRangeDate,
          filterUser: childComponent.filterUser,
          filterTransactionManager: childComponent.filterTransactionManager,
          filterMarketLeader: childComponent.filterMarketLeader,
          filterUserStatus: childComponent.filterUserStatus,
          filterTransactionAnalyst: childComponent.filterTransactionAnalyst,
        };
      }
      case ADVANCED_FILTER_TYPES.PROGRAM: {
        return {
          filterState: childComponent.filterState,
        };
      }
      case ADVANCED_FILTER_TYPES.PROJECT: {
        return {
          filterProgram: childComponent.filterProgram,
          filterProformaPhase: childComponent.filterProformaPhase,
          filterMarketLeader: childComponent.filterMarketLeader,
          filterPropertyType: childComponent.filterPropertyType,
        };
      }

      case ADVANCED_FILTER_TYPES.STATE_DATABASE: {
        return {
          filterIsStateActiveForFunding:
            childComponent.filterIsStateActiveForFunding,
        };
      }
      case ADVANCED_FILTER_TYPES.LENDER_DATABASE: {
        return {
          filterLoanSize: childComponent.filterLoanSize,
          filterPropertyType: childComponent.filterPropertyType,
          filterState: childComponent.filterState,
          filterFundingTypes: childComponent.filterFundingTypes,
          filterPreferredLender: childComponent.filterPreferredLender,
          filterIncludeProjects: childComponent.filterIncludeProjects,
          filterProjects: childComponent.filterProjects,
        };
      }
      default: {
        return {};
      }
    }
  }
}
